import { Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NextPage } from "next";
import React from "react";
import { LoginLayout } from "../layouts/pages/LoginLayout";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    backgroundColor: theme.colors.logo.darkness,
    color: theme.colors.white,
    height: "100vh",
    padding: theme.spacing(2),
    width: "100vw",
  },
  wrapper: {
    textAlign: "center",
    width: 400,
  },
  logoBtn: {
    display: "block",
  },
  logoSvg: {
    height: "auto",
    marginBottom: theme.spacing(2),
    maxWidth: 200,
    width: "100%",
  },
  heading: {
    marginBottom: theme.spacing(3),
  },
  googleBtn: {
    margin: "0 auto",
    maxWidth: 360,
    width: "100%",
  },
  signupText: {
    marginTop: theme.spacing(4),
    "& a": {
      color: theme.colors.logo.shrimp,
      fontWeight: theme.typography.fontWeightMedium,
    }
  }
}));

const Login: NextPage = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <LoginLayout title="Login" displayLoginButton>
      <Typography component="h1" className={classes.heading} variant="h4">Welcome back! Please log in</Typography>
    </LoginLayout>
  );
};

export default Login;
